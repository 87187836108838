.scroll-bar-hidden {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
/* .scroll-bar-show::-webkit-scrollbar  {
  position: fixed!important;
  right:0px!important;
  width:16px!important;
} */
::-webkit-scrollbar {
  /* position: absolute!important;
  right:0px!important; */
  width: 4px !important;
}
.scroll-bar-hidden::-webkit-scrollbar {
  width: 0px !important;
  height: 5px !important;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 32px;
}
.scroll-bar {
  min-height: calc(100vh - 260px);
}
.ant-message-notice-content {
  display: inline-block;
  padding: 14px;
  /* font-size: large; */
  font-size:20px;
  align-items: center;
  /* di */
  background: #fff;
  border-radius: 100px 4px 4px 100px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: all;
} 
.ant-message-custom-content>.anticon{
  font-size:20px !important
}
.chatbrill-a{
  color:skyblue;
}
.chatbrill-a:hover{
  color:darkblue;
  text-decoration: underline;
}